@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:focus {
  outline: none !important;
}


html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

@font-face {
    font-family: 'Gotica';
    src: url('./fonts/Gotic.eot');
    src: url('./fonts/Gotic.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Gotic.woff2') format('woff2'),
         url('./fonts/Gotic.woff') format('woff'),
         url('./fonts/Gotic.ttf')  format('truetype'),
         url('./fonts/Gotic.svg#Untitled1') format('svg');
}

.gotica-font {
  font-family: 'Gotica';
  letter-spacing: -0.06em;
  line-height: 1em;
}


.text-section{
  scrollbar-color: #fff #F6DEA9;
  scrollbar-width: 30px;
  direction: rtl;
}

@media (height: 1080px) and (width: 1920px) {
  .class-cover{
      display: none;
  }
}

